import { Application, defaultSchema } from '@hotwired/stimulus';
import { appsignal } from '@shared/js/appsignal';
import { installErrorHandler } from '@appsignal/stimulus';

const application = new Application(document.documentElement, {
  ...defaultSchema,
  actionAttribute: 'data-do'
});

application.debug = import.meta.env.DEV;

application.start();

installErrorHandler(appsignal, application);

window.$$StimulusApp$$ = application;

export { application };
