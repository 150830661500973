import Appsignal from '@appsignal/javascript';

const appsignalFrontendApiKey = import.meta.env
  .VITE_APPSIGNAL_FRONTEND_MONITORING_KEY;

export const appsignal = new Appsignal({
  key: appsignalFrontendApiKey,
  ignoreErrors: [
    /Fetch is aborted/,
    /Failed to fetch\.?/,
    /The operation is insecure\./,
    /Can't find variable: fbq\./,
    /Can't find variable: ttq\./,
    /Can't find variable _AutofillCallbackHandler/,
    /AbortError: Fetch is aborted/,
    /Request aborted/,
    /Network Error/,
    /Script error\./,
    /The operation was aborted\./,
    /Fetch was aborted\./,
    /timeout of 0ms exceeded/,
    /returnfalse is not defined/,
    /server_fp is not defined/,
    /Can't find variable: __firefox__/,
    /Abgebrochen/,
    /Load failed/,
    /Unexpected EOF/,
    /Can't find variable: __AutoFillPopupClose__/,
    /\(unknown\): Script error\./,
    /AbortError: The user aborted a request/,
    /AbortError: signal is aborted without reason/,
    /The user aborted a request\./,
    /Can't find variable _AutofillCallbackHandler/,
    /Error: \[splide\] A track\/list element is missing\./,
    /NotFoundError: The object can not be found here\./,
    /Error: \[splide\] A track\/list element is missing\./,
    /TypeError: window.Trengo.Api.Widget is undefined/,
    /TypeError: NetworkError when attempting to fetch resource/,
    /Error: 0/
  ]
});
